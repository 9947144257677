import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../utils/url";
import authHeader from "../services/auth-header";
import AddArticle from "./AddArticle";
import AddTag from "./AddTag";
import ListArticle from "./ListArticle";

const buttonStyle = {
  width: "30%",
  marginBottom: "2rem",
  marginRight: "2rem",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const Article = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [subCategoryId, setSubCategoryId] = useState("");

  // data needed in the backend when creating an article
  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  // delete - edit dialog
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState({});

  // pagination data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalPages, setTotalPages] = useState(0);
  // number of rows per page
  const [rowsPerPage, setRowsPerPage] = useState(6);

  // snackbar for errors
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";

  // dialog to add tag
  const [openDialogTagAdd, setOpenDialogTagAdd] = useState(false);
  const [tag, setTag] = useState("");

  const handleFilter = (e) => {
    if (e.target.value === "all") {
      setSubCategoryId("");
      fetchArticles(filter, rowsPerPage, currentPage, "");
    } else {
      setSubCategoryId(e.target.value);
      fetchArticles(filter, rowsPerPage, currentPage, e.target.value);
    }
  };

  const handleFilterInput = (e) => {
    setFilter(e.target.value);
    fetchArticles(e.target.value, rowsPerPage, currentPage, subCategoryId);
  };

  const addArticleToList = (article) => {
    setArticles([...articles, article]);
  };

  useEffect(() => {
    setIsLoading(true);
    // fetch all the subCategories
    fetchSubCategories();
    // fetch all the tags
    fetchTags();
    // fetch the articles
    fetchArticles();
  }, []);

  const fetchSubCategories = () => {
    // fetch all the sub categories
    axios
      .get(`${url}/sub-category/all`, { headers: authHeader() })
      .then((res) => {
        setSubCategories(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const fetchTags = () => {
    // fetch all the tags
    axios
      .get(`${url}/article/tag/all`, { headers: authHeader() })
      .then((res) => {
        setTags(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const addTag = () => {
    axios
      .post(
        `${url}/article/tag/create`,
        { title: tag },
        { headers: authHeader() }
      )
      .then((res) => {
        setTags([...tags, res.data]);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  // basic CRUD
  // fetch articles with pagination
  const fetchArticles = (
    search = "",
    itemsPerPage = 6,
    currentPage = 1,
    subCategoryId = ""
  ) => {
    // fetch all the sub categories
    axios
      .get(
        `${url}/article/all?search=${search}&itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&subCategoryId=${subCategoryId}`,
        { headers: authHeader() }
      )
      .then((res) => {
        setArticles(res.data.articles);
        // console.log(res.data.articles);
        setCurrentPage(+res.data.currentPage);
        setTotalItems(+res.data.totalItems);
        setTotalPages(+res.data.totalPages);

        // console.log(res.data.articles);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const createArticle = (formData) => {
    // create article

    setIsLoading(true);
    // prepare our data
    // send the data to the back-end
    axios
      .post(`${url}/article/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        // add it to the screen
        // console.log(res.data);
        addArticleToList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteArticle = () => {
    setIsLoading(true);

    axios
      .delete(`${url}/article/${selectedArticle._id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        fetchArticles(filter, rowsPerPage, currentPage, subCategoryId);

        // add it to the screen
        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setOpenSnackBar(true);
        setIsLoading(false);
      });
  };

  const updateArticle = (formData) => {
    // create article

    setIsLoading(true);
    // prepare our data
    // send the data to the back-end
    axios
      .patch(`${url}/article/update/${selectedArticle._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        fetchArticles(filter, rowsPerPage, currentPage, subCategoryId);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Container maxWidth="md">
      <Button
        style={buttonStyle}
        onClick={() => setOpenDialogAdd(true)}
        className="input-form"
        variant="contained"
      >
        Add Article
      </Button>
      <AddArticle
        tags={tags}
        subCategories={subCategories}
        createArticle={createArticle}
        openDialogAdd={openDialogAdd}
        setOpenDialogAdd={setOpenDialogAdd}
        isLoading={isLoading}
      />

      <Button
        style={buttonStyle}
        onClick={() => setOpenDialogTagAdd(true)}
        className="input-form"
        variant="contained"
      >
        Add Tag
      </Button>
      <AddTag
        openDialogTagAdd={openDialogTagAdd}
        setOpenDialogTagAdd={setOpenDialogTagAdd}
        addTag={addTag}
        setTag={setTag}
        tag={tag}
      />

      {/* Filter proprety */}
      <TextField
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        fullWidth
        className="input-form"
        label="Filter"
        variant="filled"
        value={filter}
        onChange={(e) => handleFilterInput(e)}
      />

      <FormControl style={{ marginBottom: "1rem" }} fullWidth variant="filled">
        <InputLabel id="demo-simple-select-standard-label">
          Sub Category
        </InputLabel>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          // onChange={(e) => setSubCategoryId(e.target.value)}
          onChange={(e) => handleFilter(e)}
          value={subCategoryId}
        >
          <MenuItem value="all">all</MenuItem>
          {subCategories.map((cat) => (
            <MenuItem key={cat._id} value={cat._id}>
              {cat.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <ListArticle
        articles={articles}
        fetchArticles={fetchArticles}
        isLoading={isLoading}
        currentPage={currentPage}
        totalItems={totalItems}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        openDialogEdit={openDialogEdit}
        setOpenDialogEdit={setOpenDialogEdit}
        openDialogDelete={openDialogDelete}
        setOpenDialogDelete={setOpenDialogDelete}
        selectedArticle={selectedArticle}
        setSelectedArticle={setSelectedArticle}
        tags={tags}
        updateArticle={updateArticle}
        subCategoryId={subCategoryId}
      />

      {/* // Dialog to verify the delete */}
      <Dialog
        open={openDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpenDialogDelete(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure ? {selectedArticle.title}{" "}
        </DialogTitle>
        <DialogActions>
          <Button
            ariant="outlined"
            color="success"
            onClick={() => {
              setOpenDialogDelete(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDialogDelete(false);
              deleteArticle();
            }}
            ariant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        open={openSnackBar}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Problem when removing the article, try again later!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Article;
