import { PhotoCamera } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const textFieldStyle = {
  marginTop: "1rem",
  marginBottom: "1rem",
};

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  marginTop: "1rem",
  marginBottom: "1rem",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const EditCategory = ({
  setOpenEditCategory,
  openEditCategory,
  selectedCategory,
  editCategory,
}) => {
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  // to preview the image to the user
  const [imageUrl, setImageUrl] = useState("");

  // change the imageUrl if the user changed this image
  const [image, setImage] = useState(undefined);
  const inputImageRef = useRef(null);
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };

  // to handle the uploaded image
  useEffect(() => {
    if (image == null || image === undefined) return;
    const newImageUrl = URL.createObjectURL(image);
    setImageUrl(newImageUrl);
  }, [image]);

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    setTitle(selectedCategory.title);
    setIcon(selectedCategory.icon);
    setDescription(selectedCategory.description);
    setImageUrl(selectedCategory.imageUrl);
  }, [
    selectedCategory.title,
    selectedCategory.description,
    selectedCategory.imageUrl,
    selectedCategory.icon,
  ]);

  const handleClose = () => {
    setOpenEditCategory(false);

    setTitle(selectedCategory.title);
    setDescription(selectedCategory.description);
    setImageUrl(selectedCategory.imageUrl);
    setIcon(selectedCategory.icon);
    setImage(undefined);
  };

  const handleSave = () => {
    //  update the category with the editCategory function
    // console.log(image);
    // console.log(imageUrl);
    editCategory(title, description, icon, image, imageUrl);
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openEditCategory}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" "}
        Edit selectedUser:{" "}
        <span style={{ color: "#dc3545" }}>{selectedCategory.title}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          className="input-form"
          style={textFieldStyle}
          fullWidth
          label="Title"
          variant="filled"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <TextField
          className="input-form"
          style={textFieldStyle}
          fullWidth
          label="Icon"
          variant="filled"
          value={icon}
          onChange={(e) => setIcon(e.target.value)}
        />
        <br />
        <TextField
          className="input-form"
          fullWidth
          label="Description"
          variant="filled"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        {/* Image */}
        <div className="input-form">
          <input
            type="file"
            ref={inputImageRef}
            accept="image/*"
            hidden
            onChange={onImageChange}
          />
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={handleUploadImage}
          >
            Upload <span style={{ width: "20px" }}></span> <PhotoCamera />
          </Button>
        </div>

        <div className="image-picker input-form ">
          {imageUrl && (
            <img style={{ objectFit: "cover" }} src={imageUrl} alt="" />
          )}
        </div>
        <br />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategory;
