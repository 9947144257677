import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AppHeader from "./ AppHeader";
import Article from "./article/Article";
import Category from "./category/Category";
import Reference from "./reference/Reference";
import Login from "./auth/Login";
import AppSidebar from "./sidebar/AppSidebar";
import SubCategory from "./subCategory/SubCategory";
import PrivateRoute from "./PrivateRoute";
import { useAuth } from "./contexts/AuthContext";
import Dashboard from "./dashboard/Dashboard";
import Users from "./users/Users";
import PrivateRouteSA from "./PrivateRouteSA";

const DefaultLayout = () => {
  const { currentUser } = useAuth();
  return (
    <div>
      {currentUser ? <AppSidebar /> : null}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        {currentUser ? <AppHeader /> : null}
        <div className="body flex-grow-1 px-3">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/admin/category"
              element={
                <PrivateRoute>
                  <Category />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/reference"
              element={
                <PrivateRoute>
                  <Reference />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/sub-category"
              element={
                <PrivateRoute>
                  <SubCategory />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/article"
              element={
                <PrivateRoute>
                  <Article />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <PrivateRouteSA>
                  <Users />
                </PrivateRouteSA>
              }
            />

            {/* Redirection to dashbord or login if not authenticated */}
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Navigate to="/admin/dashboard" />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
