import "./scss/style.scss";

import "./App.css";

import { BrowserRouter } from "react-router-dom";

import DefaultLayout from "./components/DefaultLayout";
import { AuthProvider } from "./components/contexts/AuthContext";

function App() {
  return (
    <>
      <AuthProvider>
        <BrowserRouter>
          <DefaultLayout />
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
