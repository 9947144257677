import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const AddSubCategory = ({
  openDialogAdd,
  setOpenDialogAdd,
  title,
  setTitle,
  categoryId,
  setCategoryId,
  createSubCategory,
  isLoading,
  categories,
}) => {
  // form validation
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const vertical = "top";
  const horizontal = "center";

  // handle the dialog close
  const handleClose = () => {
    setTitle("");
    setCategoryId("");
    setOpenDialogAdd(false);
  };

  const handleSubmitCreate = () => {
    if (categoryId.length === 0 || title.length === 0) {
      setOpenSnackBar(true);
      return;
    }
    createSubCategory();
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogAdd}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add Sub Category</DialogTitle>

      {openSnackBar && (
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          onClose={() => setOpenSnackBar(false)}
          autoHideDuration={3000}
          open={openSnackBar}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            All fields are required
          </Alert>
        </Snackbar>
      )}
      <DialogContent>
        <form className="basic-form">
          <TextField
            fullWidth
            className="input-form"
            required
            label="Title"
            variant="filled"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <FormControl
            required
            style={{ marginTop: "2rem" }}
            fullWidth
            variant="filled"
          >
            <InputLabel id="demo-simple-select-standard-label">
              Category
            </InputLabel>

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setCategoryId(e.target.value)}
              value={categoryId}
            >
              {categories.map((cat) => (
                <MenuItem key={cat._id} value={cat._id}>
                  {cat.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSubmitCreate}
            disabled={isLoading}
          >
            Send {isLoading && <CircularProgress />}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddSubCategory;
