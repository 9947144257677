import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { url } from "../../utils/url";
import jwt_decode from "jwt-decode";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();

  const login = (username, password) => {
    return axios
      .post(`${url}/admin/login`, {
        username,
        password,
      })
      .then((res) => {
        // console.log(res.data);
        localStorage.setItem("currentUser", JSON.stringify(res.data));
        let decodedToken = jwt_decode(res.data.accessToken);
        setCurrentUser(decodedToken);
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const logout = () => {
    localStorage.clear();
    setCurrentUser(undefined);
  };

  useEffect(() => {
    // get the current user
    const user = JSON.parse(localStorage.getItem("currentUser"));
    if (!user) return;

    // check the expiration of the token
    let decodedToken = jwt_decode(user.accessToken);
    let currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) return; // token expired

    //validate the token in the back-end
    // console.log(user);
    axios
      .post(
        `${url}/admin/validate_token`,
        {},
        {
          headers: { Authorization: "Bearer " + user.accessToken },
        }
      )
      .then((res) => {
        // console.log("Data", res.data);
        // console.log(decodedToken);
        setCurrentUser(decodedToken);
      })
      .catch((err) => {
        setCurrentUser(undefined);
        localStorage.clear();
        console.log(err.data);
      });
  }, []);

  const value = {
    currentUser,
    login,
    logout,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
