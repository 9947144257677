import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FilledInput,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { PhotoCamera } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditArticle = ({
  article,
  setOpenDialogEdit,
  openDialogEdit,
  tags,
  updateArticle,
}) => {
  // data needed in the backend
  const [title, setTitle] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [images, setImages] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [rank, setRank] = useState(1);

  // dynamic inputs
  const [additionalInformationValues, setAdditionalInformationValues] =
    useState([{ key: "", value: "" }]);

  const handleChange = (i, e) => {
    let newFormValues = [...additionalInformationValues];
    newFormValues[i][e.target.name] = e.target.value;
    setAdditionalInformationValues(newFormValues);
  };

  const addFormFields = () => {
    setAdditionalInformationValues([
      ...additionalInformationValues,
      { key: "", value: "" },
    ]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...additionalInformationValues];
    newFormValues.splice(i, 1);
    setAdditionalInformationValues(newFormValues);
  };

  // to preview the image to the user
  const [imageUrls, setImageUrls] = useState([]);

  // to create a button that refers to the input file
  const inputImageRef = useRef(null);
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };

  const handleClose = () => {
    setOpenDialogEdit(false);
  };

  useEffect(() => {
    if (images && images.length === 0) return;

    let pictures = [];
    for (let i = 0; i < images.length; i++) {
      const newImageUrl = URL.createObjectURL(images[i]);
      pictures.push(newImageUrl);
    }
    setImageUrls(pictures);
  }, [images]);

  const onImageChange = (e) => {
    // console.log(e.target.files[0]);
    if (e.target.files.length !== 0) {
      setImages(e.target.files);
    }
  };

  useEffect(() => {
    setTitle(article.title);
    setDescription1(article.description1);
    setDescription2(article.description2);
    setImageUrls(article.imageUrls);
    setRank(article.rang);
    if (article.tags) {
      setSelectedTags(article.tags[0].split(","));
    }
    if (article.additionalInformations) {
      setAdditionalInformationValues(article.additionalInformations);
    }
  }, [
    article.title,
    article.description1,
    article.description2,
    article.imageUrls,
    article.tags,
    article.additionalInformations,
    article.rang,
  ]);

  const handleSubmitUpdate = () => {
    let formData = new FormData();

    // prepare our data
    // console.log("images: ", images);
    // console.log("imageUrls", imageUrls);

    if (images.length > 0) {
      // the user has uploaded images
      for (let i = 0; i < images.length; i += 1) {
        formData.append("images", images[i]);
      }
    }

    formData.append(
      "additionalInformations",
      JSON.stringify(additionalInformationValues)
    );

    formData.append("title", title);
    formData.append("description1", description1);
    formData.append("description2", description2);
    formData.append("tags", selectedTags);
    formData.append("rang", rank);

    // console.log(formData);
    // update the article and close the dialog
    updateArticle(formData);
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add Article</DialogTitle>

      <DialogContent>
        <form className="basic-form">
          <TextField
            fullWidth
            className="input-form"
            required
            label="Title"
            variant="filled"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          {/* dynamic inputs */}
          <div style={{ marginTop: "2rem" }} />
          <h4>Fiche Technique:</h4>
          {additionalInformationValues.map((element, index) => (
            <div
              className="form-inline"
              style={{ marginTop: ".5rem" }}
              key={index}
            >
              <TextField
                required
                id="filled-basic"
                label="Key"
                variant="filled"
                name="key"
                value={element.key || ""}
                onChange={(e) => handleChange(index, e)}
              />

              <TextField
                required
                id="filled-basic"
                style={{
                  marginLeft: "2rem",
                  marginRight: ".3rem",
                  width: "55%",
                }}
                name="value"
                label="Value"
                variant="filled"
                value={element.value || ""}
                onChange={(e) => handleChange(index, e)}
              />

              {index ? (
                <IconButton
                  aria-label="delete"
                  onClick={() => removeFormFields(index)}
                  color="error"
                  style={{ marginTop: ".3rem" }}
                >
                  <DeleteIcon sx={{ fontSize: 30 }} />
                </IconButton>
              ) : null}
            </div>
          ))}

          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            onClick={() => addFormFields()}
          >
            Add
          </Button>

          {/* end dynamic inputs */}

          {/* // tags form control */}
          <FormControl style={{ marginTop: "2rem" }} fullWidth variant="filled">
            <InputLabel id="demo-multiple-chip-label">Tags</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={selectedTags}
              onChange={(e) => setSelectedTags(e.target.value)}
              input={<FilledInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {tags.map((tag) => (
                <MenuItem key={tag._id} value={tag.title}>
                  {tag.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            fullWidth
            className="input-form"
            required
            label="Description1"
            variant="filled"
            multiline
            rows={4}
            value={description1}
            onChange={(e) => setDescription1(e.target.value)}
          />
          <TextField
            fullWidth
            className="input-form"
            required
            multiline
            rows={4}
            label="Description2"
            variant="filled"
            value={description2}
            onChange={(e) => setDescription2(e.target.value)}
          />
          <div className="input-form">
            <input
              type="file"
              ref={inputImageRef}
              accept="image/*"
              hidden
              multiple
              onChange={onImageChange}
            />
            <Button
              style={buttonStyle}
              variant="contained"
              onClick={handleUploadImage}
            >
              Upload <span style={{ width: "20px" }}></span> <PhotoCamera />
            </Button>
          </div>
          <div className="article-picker input-form ">
            {imageUrls &&
              imageUrls.length > 0 &&
              imageUrls.map((imageUrl) => (
                <img key={imageUrl} src={imageUrl} alt="" />
              ))}
          </div>
          <TextField
            fullWidth
            className="input-form"
            type="number"
            required
            label="Rang"
            variant="filled"
            value={rank}
            onChange={(e) => setRank(e.target.value)}
          />
          <br />
          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSubmitUpdate}
          >
            Send
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditArticle;
