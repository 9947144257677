import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

const textFieldStyle = {
  marginTop: "1rem",
  marginBottom: "1rem",
};

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  marginTop: "1rem",
  marginBottom: "1rem",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const EditReference = ({
  setOpenDialogEdit,
  openDialogEdit,
  editReference,
  selectedReference,
}) => {
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  // to preview the image to the user
  const [imageUrl, setImageUrl] = useState("");

  // change the imageUrl if the user changed this image
  const [image, setImage] = useState(undefined);
  const inputImageRef = useRef(null);
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };

  // to handle the uploaded image
  useEffect(() => {
    if (image == null || image === undefined) return;
    const newImageUrl = URL.createObjectURL(image);
    setImageUrl(newImageUrl);
  }, [image]);

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  useEffect(() => {
    setTitle(selectedReference.title);
    setType(selectedReference.type);
    setDescription(selectedReference.description);
    setImageUrl(selectedReference.imageUrl);
    setIsFavorite(selectedReference.isFavorite);
  }, [
    selectedReference.title,
    selectedReference.description,
    selectedReference.imageUrl,
    selectedReference.type,
    selectedReference.isFavorite,
  ]);

  const handleClose = () => {
    setOpenDialogEdit(false);

    setTitle(selectedReference.title);
    setDescription(selectedReference.description);
    setImageUrl(selectedReference.imageUrl);
    setType(selectedReference.type);
    setImage(undefined);
  };

  const handleSave = () => {
    //  update the category with the editCategory function
    // console.log(image);
    // console.log(imageUrl);
    editReference(title, description, type, image, imageUrl, isFavorite);
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" "}
        Edit Reference:{" "}
        <span style={{ color: "#dc3545" }}>{selectedReference.title}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          className="input-form"
          style={textFieldStyle}
          fullWidth
          label="Title"
          variant="filled"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />

        <FormControl
          required
          style={{ marginTop: "1rem", marginBottom: "2rem" }}
          fullWidth
          variant="filled"
        >
          <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={(e) => setType(e.target.value)}
            value={type}
          >
            <MenuItem value="SOCIETE DE CONFECTION">
              SOCIETE DE CONFECTION
            </MenuItem>
            <MenuItem value="PRÊT À PORTER">PRÊT À PORTER</MenuItem>
            <MenuItem value="PRESSING ET LAVERIE">PRESSING ET LAVERIE</MenuItem>
          </Select>
        </FormControl>

        <br />
        <TextField
          className="input-form"
          fullWidth
          label="Description"
          variant="filled"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        {/* Image */}
        <div className="input-form">
          <input
            type="file"
            ref={inputImageRef}
            accept="image/*"
            hidden
            onChange={onImageChange}
          />
          <Button
            style={buttonStyle}
            variant="contained"
            onClick={handleUploadImage}
          >
            Upload <span style={{ width: "20px" }}></span> <PhotoCamera />
          </Button>
        </div>

        <div className="image-picker input-form ">
          {imageUrl && (
            <img style={{ objectFit: "cover" }} src={imageUrl} alt="" />
          )}
        </div>
        <br />
        <FormControlLabel
          control={<Checkbox />}
          checked={isFavorite}
          onChange={(e) => setIsFavorite(!isFavorite)}
          label="is Favorite"
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditReference;
