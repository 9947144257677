import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./contexts/AuthContext";

function PrivateRouteSA({ children }) {
  const { currentUser } = useAuth();
  return currentUser && currentUser.role === "sa" ? (
    children
  ) : (
    <Navigate to="/login" />
  );
}

export default PrivateRouteSA;
