import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import SendIcon from "@mui/icons-material/Send";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const AddTag = ({
  openDialogTagAdd,
  setOpenDialogTagAdd,
  addTag,
  setTag,
  tag,
}) => {
  const handleSubmitCreate = () => {
    addTag();
    handleClose();
  };

  // handle the dialog close
  const handleClose = () => {
    setTag("");
    setOpenDialogTagAdd(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogTagAdd}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add Tag</DialogTitle>

      <DialogContent>
        <form className="basic-form">
          <TextField
            fullWidth
            className="input-form"
            required
            label="Tag"
            variant="filled"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          />

          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={handleSubmitCreate}
          >
            Send{" "}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddTag;
