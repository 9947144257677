import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../utils/url";
import authHeader from "../services/auth-header";
import AddCategory from "./AddCategory";
import ListCategory from "./ListCategory";

const buttonStyle = {
  width: "30%",
  marginBottom: "2rem",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // add category dialog
  const [openAddCategory, setOpenAddCategory] = useState(false);

  // delete dialog
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState({});

  // edit category
  const [openEditCategory, setOpenEditCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/category/all`, { headers: authHeader() })
      .then((res) => {
        setCategories(res.data);
        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  }, []);

  const deleteCategory = () => {
    // send a reques and delete it from the db
    axios
      .delete(`${url}/category/${categoryToDelete._id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // get the current admin
        const index = categories.findIndex(
          (cat) => cat._id === categoryToDelete._id
        );
        setIsLoading(true);
        // delete it from the table to update it
        categories.splice(index, 1);
        setCategories(categories);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        // open snackbar to say this category contains subcategories
        if (err.response.data.statusCode === 406) {
          // open the snackbar
          setOpenSnackBar(true);
        }
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const editCategory = (title, description, icon, image, imageUrl) => {
    // get the index of the current category
    const index = categories.findIndex(
      (cat) => cat._id === selectedCategory._id
    );
    setIsLoading(true);
    // update it
    categories[index] = { ...categories[index], title, description, imageUrl };
    setCategories(categories);

    // prepare the body of the request
    let formData = new FormData();
    formData.append("title", title);
    formData.append("icon", icon);
    formData.append("description", description);
    if (image) {
      // we just update the text
      formData.append("image", image);
    }
    // if we send an image then we need to update it

    axios
      .patch(`${url}/category/update/${selectedCategory._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const addCaegoryToList = (category) => {
    setCategories([...categories, category]);
  };

  const verifyDelete = (category) => {
    setOpenDialogDelete(true);
    setCategoryToDelete(category);
  };

  return (
    <Container maxWidth="md">
      <Button
        style={buttonStyle}
        onClick={() => setOpenAddCategory(true)}
        className="input-form"
        variant="contained"
      >
        Add Category
      </Button>

      <AddCategory
        openAddCategory={openAddCategory}
        setOpenAddCategory={setOpenAddCategory}
        addCaegoryToList={addCaegoryToList}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
      />

      {/* // Dialog to verify the delete */}
      <Dialog
        open={openDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpenDialogDelete(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
        <DialogActions>
          <Button
            ariant="outlined"
            color="success"
            onClick={() => {
              setOpenDialogDelete(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDialogDelete(false);
              deleteCategory();
            }}
            ariant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <ListCategory
        categories={categories}
        isLoading={isLoading}
        verifyDelete={verifyDelete}
        setOpenEditCategory={setOpenEditCategory}
        openEditCategory={openEditCategory}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        editCategory={editCategory}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        open={openSnackBar}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          This category contains sub category try to delete them first
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Category;
