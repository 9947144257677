import React from "react";
import CIcon from "@coreui/icons-react";
import { cilDrop, cilSpeedometer } from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  //Navitem Type - Link with badge
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/admin/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "success", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "STAT",
    },
  },

  // Navitem Type Title
  {
    component: CNavTitle,
    name: "Daily Review",
  },

  //Navitem Type - Link without badge
  {
    component: CNavItem,
    name: "Users",
    to: "/admin/users",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: "danger", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "Super Admin",
    },
  },

  {
    component: CNavTitle,
    name: "Main Components",
  },

  // Navgroup type - Contains Navlinks

  {
    component: CNavItem,
    name: "Category",
    to: "/admin/category",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: "info", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "Admin",
    },
  },

  {
    component: CNavItem,
    name: "Sub Category",
    to: "/admin/sub-category",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: "info", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "Admin",
    },
  },

  {
    component: CNavItem,
    name: "Article",
    to: "/admin/article",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: "info", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "Admin",
    },
  },
  {
    component: CNavItem,
    name: "Reference",
    to: "/admin/reference",
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
    badge: {
      color: "info", // colors available: primary - secondary -success - danger - warning - info - light - dark
      text: "Admin",
    },
  },
];

export default _nav;
