import {
  Alert,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../utils/url";
import authHeader from "../services/auth-header";
import AddReference from "./AddReference";
import ListReference from "./ListReference";

const buttonStyle = {
  width: "30%",
  marginBottom: "2rem",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const Reference = () => {
  const [references, setReferences] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // filter functionality
  const [allReferences, setAllReferences] = useState([]);
  const [filter, setFilter] = useState("");

  // add - delete - edit dialog
  const [openDialogAdd, setOpenDialogAdd] = useState(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedReference, setSelectedReference] = useState({});

  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${url}/reference/all`, { headers: authHeader() })
      .then((res) => {
        setReferences(res.data);
        setAllReferences(res.data);
        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  }, []);

  const deleteReference = () => {
    // send a reques and delete it from the db
    axios
      .delete(`${url}/reference/${selectedReference._id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // get the current admin
        const index = references.findIndex(
          (cat) => cat._id === selectedReference._id
        );
        setIsLoading(true);
        // delete it from the table to update it
        references.splice(index, 1);
        setReferences(references);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);

        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const editReference = (
    title,
    description,
    type,
    image,
    imageUrl,
    isFavorite
  ) => {
    // get the index of the current reference
    const index = references.findIndex(
      (cat) => cat._id === selectedReference._id
    );
    setIsLoading(true);
    // update it
    references[index] = {
      ...references[index],
      title,
      type,
      description,
      imageUrl,
      isFavorite,
    };
    setReferences(references);

    // prepare the body of the request
    let formData = new FormData();
    formData.append("title", title);
    formData.append("type", type);
    formData.append("description", description);
    formData.append("isFavorite", isFavorite);
    if (image) {
      // we just update the text
      formData.append("image", image);
    }
    // if we send an image then we need to update it

    axios
      .patch(`${url}/reference/update/${selectedReference._id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const addReferenceToList = (reference) => {
    setReferences([...references, reference]);
  };

  const verifyDelete = (reference) => {
    setOpenDialogDelete(true);
    setSelectedReference(reference);
  };

  const filterData = (e) => {
    setFilter(e.target.value);
    if (e.target.value === "") {
      setReferences(allReferences);
      return;
    }
    const newReferences = allReferences.filter(
      (ref) => ref.type === e.target.value
    );
    setReferences(newReferences);
  };

  return (
    <Container maxWidth="md">
      <Button
        style={buttonStyle}
        onClick={() => setOpenDialogAdd(true)}
        className="input-form"
        variant="contained"
      >
        Add Reference
      </Button>

      <AddReference
        openDialogAdd={openDialogAdd}
        setOpenDialogAdd={setOpenDialogAdd}
        addReferenceToList={addReferenceToList}
      />

      {/* // Dialog to verify the delete */}
      <Dialog
        open={openDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpenDialogDelete(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
        <DialogActions>
          <Button
            ariant="outlined"
            color="success"
            onClick={() => {
              setOpenDialogDelete(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDialogDelete(false);
              deleteReference();
            }}
            ariant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <FormControl
        required
        style={{ marginTop: "2rem" }}
        fullWidth
        variant="filled"
      >
        <InputLabel id="demo-simple-select-standard-label">Filter</InputLabel>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          onChange={(e) => filterData(e)}
          value={filter}
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="SOCIETE DE CONFECTION">
            SOCIETE DE CONFECTION
          </MenuItem>
          <MenuItem value="PRÊT À PORTER">PRÊT À PORTER</MenuItem>
          <MenuItem value="PRESSING ET LAVERIE">PRESSING ET LAVERIE</MenuItem>
        </Select>
      </FormControl>

      <ListReference
        references={references}
        isLoading={isLoading}
        verifyDelete={verifyDelete}
        setOpenDialogEdit={setOpenDialogEdit}
        openDialogEdit={openDialogEdit}
        selectedReference={selectedReference}
        setSelectedReference={setSelectedReference}
        editReference={editReference}
      />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        onClose={() => setOpenSnackBar(false)}
        autoHideDuration={4000}
        open={openSnackBar}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error while deleting the reference
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Reference;
