import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import EditSubCategory from "./EditSubCategory";

// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3c4b64",
    color: "rgb(230,230,230)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ListSubCategory = ({
  subCategories,
  isLoading,
  editSubCategory,
  openDialogEdit,
  setOpenDialogEdit,
  selectedSubCategory,
  setSelectedSubCategory,
  openDialogDelete,
  setOpenDialogDelete,
  deleteSubCategory,
}) => {
  const openEditDialog = (subCategory) => {
    setSelectedSubCategory(subCategory);
    setOpenDialogEdit(true);
  };

  return (
    <div style={{ marginBottom: "2rem", marginTop: "2rem" }}>
      <h2>List SubCategories</h2>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Sub Category </StyledTableCell>
                <StyledTableCell>Category </StyledTableCell>

                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {subCategories.map((subCategory) => (
                <StyledTableRow key={subCategory._id}>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>{subCategory.title}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>
                      {subCategory.category.title}
                    </p>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <IconButton
                      onClick={() => openEditDialog(subCategory)}
                      aria-label="edit"
                      color="success"
                    >
                      <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenDialogDelete(true);
                        setSelectedSubCategory(subCategory);
                      }}
                      aria-label="delete"
                      color="error"
                    >
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditSubCategory
        selectedSubCategory={selectedSubCategory}
        editSubCategory={editSubCategory}
        openDialogEdit={openDialogEdit}
        setOpenDialogEdit={setOpenDialogEdit}
      />

      {/* // Dialog to verify the delete */}
      <Dialog
        open={openDialogDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={() => {
          setOpenDialogDelete(false);
        }}
      >
        <DialogTitle id="alert-dialog-title">Are you sure ?</DialogTitle>
        <DialogActions>
          <Button
            ariant="outlined"
            color="success"
            onClick={() => {
              setOpenDialogDelete(false);
            }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              setOpenDialogDelete(false);
              deleteSubCategory();
            }}
            ariant="outlined"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ListSubCategory;
