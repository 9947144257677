import React, { useEffect, useState } from "react";

import { CButton, CSidebar, CSidebarBrand, CSidebarNav } from "@coreui/react";
import "./navbar.css";

import { AppSidebarNav } from "./AppSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "./_navData";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const logo = require("../../assets/logo/cadLogo1.png");

const AppSidebar = () => {
  const [newNavigation, setnewNavigation] = useState(navigation);
  const { logout } = useAuth();
  const navigate = useNavigate();

  const { currentUser } = useAuth();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    if (currentUser.role === "a") {
      // console.log("Current User", currentUser);

      const filtredNavigation = navigation.filter(
        (nav) => nav?.badge?.text !== "Super Admin"
      );
      setnewNavigation(filtredNavigation);
    }
  }, []);

  return (
    <CSidebar id="adminSidebar" position="fixed">
      <CSidebarBrand className="d-none d-md-flex company-admin-logo" to="/">
        <img src={logo} alt="" />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={newNavigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarBrand className="d-none d-md-flex company-admin-logo">
        <CButton
          color="link"
          onClick={handleLogout}
          className="logout-admin-button"
        >
          Logout
        </CButton>
      </CSidebarBrand>
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
