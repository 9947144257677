import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import React from "react";
import { Box } from "@mui/system";

const TablePaginationActions = ({ page, onPageChange, rowsPerPage, count }) => {
  const currentPage = page + 1;
  const totalRows = count;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const handleNextPageClick = (event) => {
    // console.log("Next clicked");
    onPageChange(event, currentPage + 1);
  };

  const handleLastPageClick = (event) => {
    // console.log(totalPages);
    onPageChange(event, totalPages);

    // console.log("Last page clicked");
  };

  const handlePreviousPageClick = (event) => {
    // console.log("Prev clicked");
    onPageChange(event, currentPage - 1);
  };

  const handleFirsPageClick = (event) => {
    onPageChange(event, 1);
    // console.log("First clicked");
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        disabled={currentPage === 1}
        onClick={handleFirsPageClick}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        disabled={currentPage === 1}
        onClick={handlePreviousPageClick}
        aria-label="Previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>

      <IconButton
        disabled={currentPage === totalPages}
        onClick={handleNextPageClick}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={currentPage === totalPages}
        onClick={handleLastPageClick}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

export default TablePaginationActions;
