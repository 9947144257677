import React, { useEffect, useRef, useState } from "react";
import { PhotoCamera } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { url } from "../../utils/url";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import authHeader from "../services/auth-header";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const AddReference = ({
  openDialogAdd,
  setOpenDialogAdd,
  addReferenceToList,
}) => {
  // data needed in the backend
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);

  const [image, setImage] = useState(undefined);

  // loading data
  const [isLoading, setIsLoading] = useState(false);

  // to preview the image to the user
  const [imageUrl, setImageUrl] = useState(undefined);

  // form validation
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";

  // to create a button that refers to the input file
  const inputImageRef = useRef(null);
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };

  // to handle the uploaded image
  useEffect(() => {
    if (image == null || image === undefined) return;
    const newImageUrl = URL.createObjectURL(image);
    setImageUrl(newImageUrl);
  }, [image]);

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // send data to the server
  const addReference = (e) => {
    // validate the data
    if (title === "" || type === "" || !image) {
      setOpenSnackBar(true);
      setTimeout(() => setOpenSnackBar(false), 4000);
      return;
    }

    setIsLoading(true);
    // prepare our data
    let formData = new FormData();
    formData.append("image", image);
    formData.append("type", type);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("isFavorite", isFavorite);

    // send the data to the back-end
    axios
      .post(`${url}/reference/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        addReferenceToList({
          _id: res.data._id,
          imageUrl: imageUrl,
          type: type,
          isFavorite,
          description: res.data.description,
          title: res.data.title,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    handleClose();
  };

  // handle the dialog close
  const handleClose = () => {
    setTitle("");
    setDescription("");
    setType("");
    setImageUrl("");
    setImage(undefined);
    setOpenDialogAdd(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogAdd}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add Reference</DialogTitle>

      {openSnackBar && (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar}>
          <Alert severity="error" sx={{ width: "100%" }}>
            All fields are required
          </Alert>
        </Snackbar>
      )}
      <DialogContent>
        <form className="basic-form">
          <TextField
            fullWidth
            className="input-form"
            required
            label="Title"
            variant="filled"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <FormControl
            required
            style={{ marginTop: "2rem" }}
            fullWidth
            variant="filled"
          >
            <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>

            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              <MenuItem value="SOCIETE DE CONFECTION">
                SOCIETE DE CONFECTION
              </MenuItem>
              <MenuItem value="PRÊT À PORTER">PRÊT À PORTER</MenuItem>
              <MenuItem value="PRESSING ET LAVERIE">
                PRESSING ET LAVERIE
              </MenuItem>
            </Select>
          </FormControl>

          <TextField
            className="input-form"
            fullWidth
            label="Description"
            variant="filled"
            multiline
            required
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <FormControlLabel
            control={<Checkbox />}
            checked={isFavorite}
            onChange={(e) => setIsFavorite(!isFavorite)}
            label="is Favorite"
          />

          <div className="input-form">
            <input
              type="file"
              ref={inputImageRef}
              accept="image/*"
              hidden
              onChange={onImageChange}
            />
            <Button
              style={buttonStyle}
              variant="contained"
              onClick={handleUploadImage}
            >
              Upload <span style={{ width: "20px" }}></span> <PhotoCamera />
            </Button>
          </div>

          <div className="image-picker input-form ">
            {image && <img src={imageUrl} alt="" />}
          </div>

          <br />

          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={addReference}
            disabled={isLoading}
          >
            Send
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddReference;
