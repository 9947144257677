import React from "react";
import { CContainer, CHeader } from "@coreui/react";

const logo = require("../assets/logo/cadLogo1.png");

const AppHeader = () => {
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer className="header-logo-container" fluid>
        <img className="logo-header" src={logo} alt="" />
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
