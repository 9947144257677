import React, { useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { PhotoCamera } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import { url } from "../../utils/url";
import authHeader from "../services/auth-header";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const AddCategory = ({
  openAddCategory,
  setOpenAddCategory,
  addCaegoryToList,
  setIsLoading,
  isLoading
}) => {
  // data needed in the backend
  const [title, setTitle] = useState("");
  const [icon, setIcon] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(undefined);

  

  // to preview the image to the user
  const [imageUrl, setImageUrl] = useState(undefined);

  // form validation
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const vertical = "top";
  const horizontal = "center";

  // to create a button that refers to the input file
  const inputImageRef = useRef(null);
  const handleUploadImage = () => {
    inputImageRef.current.click();
  };

  // to handle the uploaded image
  useEffect(() => {
    if (image == null || image === undefined) return;
    const newImageUrl = URL.createObjectURL(image);
    setImageUrl(newImageUrl);
  }, [image]);

  const onImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  // send data to the server
  const addCategory = (e) => {
    // validate the data
    if (title === "" || description === "" || icon === "" || !image) {
      setOpenSnackBar(true);
      setTimeout(() => setOpenSnackBar(false), 4000);
      return;
    }

    setIsLoading(true);
    // prepare our data
    let formData = new FormData();
    formData.append("image", image);
    formData.append("icon", icon);
    formData.append("title", title);
    formData.append("description", description);

    // send the data to the back-end
    axios
      .post(`${url}/category/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: authHeader().Authorization,
        },
      })
      .then((res) => {
        addCaegoryToList({
          _id: res.data._id,
          imageUrl: imageUrl,
          description: res.data.description,
          title: res.data.title,
        });
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    handleClose();
  };

  // handle the dialog close
  const handleClose = () => {
    setTitle("");
    setDescription("");
    setImageUrl("");
    setImage(undefined);
    setOpenAddCategory(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openAddCategory}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>Add Category</DialogTitle>

      {openSnackBar && (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={openSnackBar}>
          <Alert severity="error" sx={{ width: "100%" }}>
            All fields are required
          </Alert>
        </Snackbar>
      )}
      <DialogContent>
        <form className="basic-form">
          <TextField
            fullWidth
            className="input-form"
            required
            label="Title"
            variant="filled"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            fullWidth
            className="input-form"
            required
            label="Icon"
            variant="filled"
            value={icon}
            onChange={(e) => setIcon(e.target.value)}
          />
          <p>
            You must select the icons from
            <a
              href="https://fontawesome.com/v4/icons/"
              rel="noreferrer"
              target="_blank"
            >
              here
            </a>
          </p>

          <TextField
            className="input-form"
            fullWidth
            label="Description"
            variant="filled"
            multiline
            required
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div className="input-form">
            <input
              type="file"
              ref={inputImageRef}
              accept="image/*"
              hidden
              onChange={onImageChange}
            />
            <Button
              style={buttonStyle}
              variant="contained"
              onClick={handleUploadImage}
            >
              Upload <span style={{ width: "20px" }}></span> <PhotoCamera />
            </Button>
          </div>

          <div className="image-picker input-form ">
            {image && <img src={imageUrl} alt="" />}
          </div>

          <br />

          <Button
            style={buttonStyle}
            className="input-form"
            variant="contained"
            endIcon={<SendIcon />}
            onClick={addCategory}
            disabled={isLoading}
          >
            Send {isLoading && <CircularProgress />}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCategory;
