import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import EditReference from "./EditReference";

// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3c4b64",
    color: "rgb(230,230,230)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const imageStyle = {
  width: "100px",
  height: "70px",
  objectFit: "cover",
};

const ListReference = ({
  references,
  isLoading,
  verifyDelete,
  setOpenDialogEdit,
  openDialogEdit,
  selectedReference,
  setSelectedReference,
  editReference,
}) => {
  const openEditCategoryDialog = (reference) => {
    setSelectedReference(reference);
    setOpenDialogEdit(true);
  };

  return (
    <div style={{ marginBottom: "2rem" }}>
      <h2>List References</h2>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title </StyledTableCell>
                <StyledTableCell>Type </StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell>Accueil</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {references.map((reference) => (
                <StyledTableRow key={reference._id}>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>{reference.title}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>{reference.type}</p>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <p style={{ fontSize: "1rem" }}>{reference.description}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <img style={imageStyle} src={reference.imageUrl} alt="" />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <p style={{ fontSize: "1rem" }}>
                      {reference.isFavorite ? "Oui" : "Non"}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      onClick={() => openEditCategoryDialog(reference)}
                      aria-label="delete"
                      color="success"
                    >
                      <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <IconButton
                      onClick={() => verifyDelete(reference)}
                      aria-label="delete"
                      color="error"
                    >
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditReference
        setOpenDialogEdit={setOpenDialogEdit}
        openDialogEdit={openDialogEdit}
        editReference={editReference}
        selectedReference={selectedReference}
      />
    </div>
  );
};

export default ListReference;
