import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import EditCategory from "./EditCategory";

// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3c4b64",
    color: "rgb(230,230,230)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const imageStyle = {
  width: "180px",
  height: "120px",
  objectFit: "cover",
};

const ListCategory = ({
  categories,
  isLoading,
  verifyDelete,
  setOpenEditCategory,
  openEditCategory,
  selectedCategory,
  setSelectedCategory,
  editCategory,
}) => {
  const openEditCategoryDialog = (category) => {
    setSelectedCategory(category);
    setOpenEditCategory(true);
  };

  return (
    <div style={{ marginBottom: "2rem" }}>
      <h2>List categories</h2>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title </StyledTableCell>
                <StyledTableCell>Icon </StyledTableCell>
                <StyledTableCell>Description</StyledTableCell>
                <StyledTableCell>Image</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {categories.map((category) => (
                <StyledTableRow key={category._id}>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>{category.title}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <p style={{ fontSize: "1rem" }}>
                      {/* <i className={category.icon} aria-hidden="true"></i> */}
                      {category.icon}
                    </p>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <p style={{ fontSize: "1rem" }}>{category.description}</p>
                  </StyledTableCell>
                  <StyledTableCell>
                    <img style={imageStyle} src={category.imageUrl} alt="" />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      onClick={() => openEditCategoryDialog(category)}
                      aria-label="delete"
                      color="success"
                    >
                      <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                    <IconButton
                      onClick={() => verifyDelete(category)}
                      aria-label="delete"
                      color="error"
                    >
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <EditCategory
        setOpenEditCategory={setOpenEditCategory}
        openEditCategory={openEditCategory}
        selectedCategory={selectedCategory}
        editCategory={editCategory}
      />
    </div>
  );
};

export default ListCategory;
