import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const MoreInformationArticle = ({
  article,
  openDialogMoreInformation,
  setOpenDialogMoreInformation,
}) => {
  const handleClose = () => {
    setOpenDialogMoreInformation(false);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogMoreInformation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" "}
        Selected Article:{" "}
        <span style={{ color: "#dc3545" }}>{article.title}</span>
      </DialogTitle>
      <DialogContent>
        <p className="more-info-field">
          <span>Sous-Categorie:</span>
          {article.subCategory && article.subCategory.title}
        </p>
        <hr />
        <p className="more-info-field">
          <span>Title:</span>
          {article.title}
        </p>
        <hr />

        <p className="more-info-field">
          <span>Images:</span>
          <br />
          {article.imageUrls &&
            article.imageUrls.map((imageUrl) => (
              <img
                key={imageUrl}
                style={{ width: "350px", margin: "1rem" }}
                src={imageUrl}
                alt=""
              />
            ))}
        </p>
        <hr />

        <p className="more-info-field">
          <span>Description1:</span>
          {article.description1}
        </p>
        <hr />
        <p className="more-info-field">
          <span>Description2:</span>

          {article.description2 &&
            article.description2
              .split(".")
              .splice(0, article.description2.split(".").length - 1)
              .map((desc) => <li key={desc}>{desc}.</li>)}
        </p>
        <hr />

        <p className="more-info-field">
          <span>Additional Inforamtion:</span>
          {article &&
            article.additionalInformations &&
            article.additionalInformations.map((art) => (
              <li key={art.key}>
                {art.key} : {art.value}
              </li>
            ))}
        </p>
        <hr />

        <p className="more-info-field">
          <span>Tags:</span>
          {article.tags && article.tags[0].replaceAll(",", " - ")}{" "}
        </p>
        <hr />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MoreInformationArticle;
