import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TablePaginationActions from "./TablePaginationActions";
import MoreInformationArticle from "./MoreInformationArticle";
import EditArticle from "./EditArticle";

// Styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3c4b64",
    color: "rgb(230,230,230)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const imageStyle = {
  width: "180px",
  height: "120px",
  objectFit: "cover",
};

const ListArticle = ({
  articles,
  fetchArticles,
  isLoading,
  currentPage,
  totalItems,
  rowsPerPage,
  setRowsPerPage,
  openDialogEdit,
  setOpenDialogEdit,
  setOpenDialogDelete,
  setSelectedArticle,
  selectedArticle,
  tags,
  updateArticle,
  subCategoryId,
}) => {
  const [openDialogMoreInformation, setOpenDialogMoreInformation] =
    useState(false);

  const handleChangePage = (event, newPage) => {
    fetchArticles("", rowsPerPage, newPage, subCategoryId);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value, 10);
    setRowsPerPage(value);
    const newPage = 1;
    fetchArticles("", value, newPage, subCategoryId);
  };

  const openDialogMoreInformationArticle = (article) => {
    setSelectedArticle(article);
    setOpenDialogMoreInformation(true);
  };

  const openEditArticleDialog = (article) => {
    setSelectedArticle(article);
    setOpenDialogEdit(true);
  };

  const openDeleteArticleDialog = (article) => {
    setOpenDialogDelete(true);
    setSelectedArticle(article);
  };

  return (
    <div style={{ marginBottom: "2rem" }}>
      <h2>List Articles</h2>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell>Description1</StyledTableCell>
                <StyledTableCell>Image</StyledTableCell>

                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {articles.map((article) => (
                <StyledTableRow key={article._id}>
                  <StyledTableCell>{article.title}</StyledTableCell>
                  <StyledTableCell>{article.description1}</StyledTableCell>
                  <StyledTableCell>
                    <img style={imageStyle} src={article.imageUrls[0]} alt="" />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton
                      aria-label="more"
                      onClick={(e) => openDialogMoreInformationArticle(article)}
                    >
                      <MoreVertIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => openEditArticleDialog(article)}
                      color="success"
                    >
                      <EditIcon sx={{ fontSize: 30 }} />
                    </IconButton>

                    <IconButton
                      aria-label="delete"
                      onClick={(e) => openDeleteArticleDialog(article)}
                      color="error"
                    >
                      <DeleteIcon sx={{ fontSize: 30 }} />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>

            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[6, 12, 15, 20]}
                  colSpan={0}
                  count={totalItems}
                  rowsPerPage={rowsPerPage}
                  page={currentPage - 1}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      )}

      <MoreInformationArticle
        article={selectedArticle}
        openDialogMoreInformation={openDialogMoreInformation}
        setOpenDialogMoreInformation={setOpenDialogMoreInformation}
      />

      <EditArticle
        article={selectedArticle}
        openDialogEdit={openDialogEdit}
        setOpenDialogEdit={setOpenDialogEdit}
        tags={tags}
        updateArticle={updateArticle}
      />
    </div>
  );
};

export default ListArticle;
