import { Button, Container, TextField } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { url } from "../../utils/url";
import authHeader from "../services/auth-header";
import AddSubCategory from "./AddSubCategory";
import ListSubCategory from "./ListSubCategory";

const buttonStyle = {
  width: "30%",
  marginRight: "5%",
  backgroundColor: "#3c4b64",
  color: "rgb(230,230,230)",
};

const SubCategory = () => {
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("");

  // data needed in the backend when creating a sub category
  const [title, setTitle] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [openDialogAdd, setOpenDialogAdd] = useState(false);

  // delete - edit dialog
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState({});

  const addCaegoryToList = (subCategory) => {
    setSubCategories([...subCategories, subCategory]);
    setAllSubCategories([...subCategories, subCategory]);
  };

  useEffect(() => {
    setIsLoading(true);
    // fetch all the categories
    axios
      .get(`${url}/category/all`, { headers: authHeader() })
      .then((res) => {
        setCategories(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });

    fetchSubCategories();
  }, []);

  // basic CRUD
  const fetchSubCategories = () => {
    // fetch all the sub categories
    axios
      .get(`${url}/sub-category/all`, { headers: authHeader() })
      .then((res) => {
        setSubCategories(res.data);
        setAllSubCategories(res.data);
        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const createSubCategory = () => {
    // create sub category
    // console.log(title);
    // console.log(categoryId);

    setIsLoading(true);
    // prepare our data
    // send the data to the back-end
    axios
      .post(
        `${url}/sub-category/create`,
        { title, categoryId },
        { headers: authHeader() }
      )
      .then((res) => {
        // add it to the screen
        // console.log(res.data);
        addCaegoryToList(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editSubCategory = (title) => {
    // update sub category
    setIsLoading(true);
    axios
      .patch(
        `${url}/sub-category/update/${selectedSubCategory._id}`,
        { title },
        { headers: authHeader() }
      )
      .then((res) => {
        // update the sub category
        const index = subCategories.findIndex(
          (subCat) => subCat._id === selectedSubCategory._id
        );
        subCategories[index].title = title;
        setSubCategories(subCategories);

        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const deleteSubCategory = () => {
    // delete the data
    setIsLoading(true);
    axios
      .delete(`${url}/sub-category/delete/${selectedSubCategory._id}`, {
        headers: authHeader(),
      })
      .then((res) => {
        // update the sub category
        const newSubCategories = subCategories.filter(
          (subCat) => subCat._id !== selectedSubCategory._id
        );

        setSubCategories(newSubCategories);

        // console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        // handle unauthorized error
        if (err.response.data.statusCode === 401) {
          // authService.logout();
        }
      });
  };

  const handleFilter = (inputText) => {
    setFilter(inputText);
    const filtredData = allSubCategories.filter(
      (subCat) =>
        subCat.category.title.toLowerCase().includes(inputText.toLowerCase()) ||
        subCat.title.toLowerCase().includes(inputText.toLowerCase())
    );
    setSubCategories(filtredData);
  };

  return (
    <Container maxWidth="md">
      <Button
        style={buttonStyle}
        onClick={() => setOpenDialogAdd(true)}
        className="input-form"
        variant="contained"
      >
        Add Sub Category
      </Button>

      <AddSubCategory
        title={title}
        setTitle={setTitle}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        categories={categories}
        createSubCategory={createSubCategory}
        openDialogAdd={openDialogAdd}
        setOpenDialogAdd={setOpenDialogAdd}
        isLoading={isLoading}
      />

      {/* Filter proprety */}
      <TextField
        style={{ marginTop: "2rem" }}
        fullWidth
        className="input-form"
        label="Filter"
        variant="filled"
        value={filter}
        onChange={(e) => {
          handleFilter(e.target.value);
        }}
      />

      <ListSubCategory
        subCategories={subCategories}
        editSubCategory={editSubCategory}
        selectedSubCategory={selectedSubCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        deleteSubCategory={deleteSubCategory}
        openDialogEdit={openDialogEdit}
        setOpenDialogEdit={setOpenDialogEdit}
        openDialogDelete={openDialogDelete}
        setOpenDialogDelete={setOpenDialogDelete}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default SubCategory;
