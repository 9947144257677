import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const textFieldStyle = {
  marginTop: "1rem",
  marginBottom: "1rem",
};

const EditSubCategory = ({
  setOpenDialogEdit,
  openDialogEdit,
  selectedSubCategory,
  editSubCategory,
}) => {
  const [title, setTitle] = useState(selectedSubCategory.title);

  useEffect(() => {
    setTitle(selectedSubCategory.title);
  }, [selectedSubCategory.title]);

  const handleClose = () => {
    setOpenDialogEdit(false);
  };

  const handleSave = () => {
    //  update the category with the editCategory function
    editSubCategory(title);
    handleClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      open={openDialogEdit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {" "}
        Edit :{" "}
        <span style={{ color: "#dc3545" }}>{selectedSubCategory.title}</span>
      </DialogTitle>
      <DialogContent>
        <TextField
          className="input-form"
          style={textFieldStyle}
          fullWidth
          label="Title"
          variant="filled"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubCategory;
